import React, { useState } from 'react';
import Section from '../components/Section';

import FrictionGif from '../assets/images/friction_lab.gif';
import ForcesGif from '../assets/images/forces_lab.gif';
import ChemicalGif from '../assets/images/lechateliertypical.gif';
import CollisionGif from '../assets/images/collisiontheorytypical.gif';
import FrictionThumbnail from '../assets/images/friction_lab_thumbnail.png';
import ForcesThumbnail from '../assets/images/forces_and_mass_lab_thumbnail.png';
import ChemicalThumbnail from '../assets/images/chemical-equilibrium-lab.png';
import CollisionThumbnail from '../assets/images/collision-theory.png';
import CovalentThumbnail from '../assets/images/covalent-bond-pic.png';

import TextColumn from '../components/TextColumn';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'

const Simulations = (props) => {
  const [physicsLabs, setChange] = useState([
    {
      key: '1',
      link: '/simulations/simulation-lab/forces-lab',
      name: 'Force and Mass Lab',
      desc: "Play with F=ma. Help a penguin get back home by launching it across unusual experiments. Tinker with the mass (kg) to alter the penguin's speed.",
      index: 0,
      simulationPic: ForcesThumbnail,
      simulationGif: ForcesGif,
      isChange: false
    },
    {
      key: '2',
      link: '/simulations/simulation-lab/friction-lab',
      name: 'Friction Lab',
      desc: 'Get a sense of friction and forces. Find the best surface for a penguin to slide on to help it get back home. Design multiple scenarios to test out how resistance affects force (F=ma) in a hands-on way.',
      index: 1,
      simulationPic: FrictionThumbnail,
      simulationGif: FrictionGif,
      isChange: false
    }
  ]);

  const [chemistryLabs, setChemistryChange] = useState([
    {
      key: '3',
      link: '/simulations/simulation-lab/collision-theory-lab',
      name: 'Collision Theory Lab',
      desc: 'Observe collision theory between molecules in chemistry. These are single molecules (blue and black) that can collide and create a new diatomic molecule (red). The two molecules "bond" together forever and turn into a red color. ',
      index: 0,
      simulationPic: CollisionThumbnail,
      simulationGif: CollisionGif,
      isChange: false
    }
  ])

  const updateLabStatus = (index) => {
    let newArr = [...physicsLabs];
    newArr[index].isChange = !newArr[index].isChange;
    
    setChange(newArr);
  }

  const updateChemistryLabStatus = (index) => {
    let newArr = [...chemistryLabs];
    newArr[index].isChange = !newArr[index].isChange;
    
    setChemistryChange(newArr);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Modulus Labs | Simulations</title>
      </Helmet>
      <Section
        style={
          {background: '#008D9B'}
        }
        content={
          <React.Fragment>
            <i className="im im-menu"></i>
            <div className='ht-sim-desc'>
              <TextColumn 
                headerText='How to use Simulations'
                descText='Click on the picture
                          that is tailored to a topic that you 
                          wish to explore!'
              />
            </div>
          </React.Fragment>
        }
        className={props.width > 760 ? 'col-section ge452l quarter-section' : 'col-section ge452l half-section'}
      />
      <Section 
        style={
          {background: '#007493'}
        }
        content={
          <React.Fragment>
            <div>
              <h1 className='sub-h sub-hh left-align'>Physics</h1>
            </div>
            {physicsLabs.map(lab => {
              return (
                <div key={lab.key} className={props.width > 760 ? 'row-section jkdhf3' : 'col-section ge452l'}>
                  <div>
                    <Link
                      to={lab.link}
                    >
                      <img
                        onMouseEnter={() => updateLabStatus(lab.index)} 
                        onMouseLeave={() => updateLabStatus(lab.index)}  
                        className='column-image' 
                        src={lab.isChange ? lab.simulationGif: lab.simulationPic}
                        alt={lab.desc}  
                      >
                      </img>
                    </Link>
                  </div>
                  <div className='box-desc'>
                    <TextColumn 
                      headerText={lab.name}
                      descText={lab.desc}
                    />
                  </div>
                </div>
              );
            })}
            <div>
              <h1 className='sub-h sub-hh left-align'>Chemistry</h1>
            </div>
            {chemistryLabs.map(lab => {
              return (
                <div key={lab.key} className={props.width > 760 ? 'row-section jkdhf3' : 'col-section ge452l'}>
                  <div>
                    <Link
                      to={lab.link}
                    >
                      <img
                        onMouseEnter={() => updateChemistryLabStatus(lab.index)} 
                        onMouseLeave={() => updateChemistryLabStatus(lab.index)}  
                        className='column-image' 
                        src={lab.isChange ? lab.simulationGif: lab.simulationPic}
                        alt={lab.desc}  
                      >
                      </img>
                    </Link>
                  </div>
                  <div className='box-desc'>
                    <TextColumn 
                      headerText={lab.name}
                      descText={lab.desc}
                    />
                  </div>
                </div>
              );
            })}
          </React.Fragment>
          
        }
        className={props.width > 760 ? 
                  'col-section ge452l lab-section ge452l' :      
                  'col-section ge452l mobile-section ge452l'}
      />
      
    </React.Fragment>

  );
}

export default Simulations;