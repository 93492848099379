import React, {useState, useEffect} from 'react';

import LessonPlan from '../components/LessonPlan';
import PDFModal from '../components/PDFModal';
import Section from '../components/Section';
import TextColumn from '../components/TextColumn';

import { Helmet } from 'react-helmet';

const TeacherResources = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  return (
    <>
      <Helmet>
        <title>Modulus Labs | Teacher Resources</title>
      </Helmet>
      <Section
        style={
          {background: '#008D9B'}
        }
        content={
          <>
            <i className="im im-menu"></i>
            <div className='ht-sim-desc'>
              <TextColumn 
                headerText='Resource for Teachers'
                descText='Simulations are interactive web-based labs that students can play on their laptops (e.g. Chromebooks) 
                        inside or outside the classroom. Simulations are best deployed live from the instructor using a guided 
                        lesson plan in classrooms with students present, or via distance-learning.'
              />
              <TextColumn
                subHeaderText='Sample Lesson Plan'
                descText='Below is a sample lesson plan that was taught in a Physics class using two of our simulations, 
                            played consecutively in one lab session (1-2 hrs with discussion).'
              />
              <LessonPlan
                headerText='Sample Physics Lesson Plan: Forces + Friction'
                setIsOpen={setIsOpen}
                setPageNumber={setPageNumber}
              />
              <TextColumn
                subHeaderText='Try it Yourself:'
              />
              <TextColumn
                descText={<>
                  <strong>Part 1: </strong> 
                   Force and Mass (
                  <a
                    className='lab-link' 
                    href='https://moduluslabs.org/simulations/simulation-lab/forces-lab'
                  >
                    Link
                  </a>
                   )
                  </>}
              />
              <TextColumn
                descText={<>
                  <strong>Part 2: </strong> 
                  Friction (
                  <a
                    className='lab-link'
                    href='https://moduluslabs.org/simulations/simulation-lab/friction-lab'
                  >
                    Link
                  </a>
                  )
                  </>}
              />
            </div>
            <PDFModal 
              setOpen={() => setIsOpen(!isOpen)}
              open={isOpen}
              pageNumber={pageNumber}
            />
          </>
        }
        className={props.width > 760 ? 'col-section ge452l teacher-section' : 'col-section ge452l teacher-section'}
      />
    </>
  )
}

export default TeacherResources;