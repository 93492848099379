import React from 'react';
import { ReactComponent as InstagramLogo } from '../assets/images/iconmonstr-instagram-13.svg';
import { ReactComponent as YoutubeLogo } from '../assets/images/iconmonstr-youtube-8.svg';

import { Link, useLocation } from 'react-router-dom';

const Footer = (props) => {
  function TopFooter() {
    const location = useLocation();
    if (location.pathname !== '/simulations/simulation-lab/forces-lab' &&
        location.pathname !== '/simulations/simulation-lab/friction-lab') {
      return (
        <React.Fragment>
          <div className='a-3ugir'>
            <ul 
                className={props.width > 760 ? 
                           'nav-list top-footer-row footer-row-section' :
                           'nav-list footer-col-section'
            }>
              <li className='footer-nav-item'>
                <Link
                  className='nav-link'
                  to='/about'
                >
                  About
                </Link>
              </li>
              <li className='footer-nav-item'>
                <Link
                  className='nav-link'
                  to='/simulations'
                >
                  Simulations
                </Link>
              </li>
              <li className='footer-nav-item'>
                <Link
                  className='nav-link'
                  to='/contact'
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <Link
              className='nav-link'
              to='/terms-and-conditions'
            >
              Terms and Conditions
            </Link>
          </div>
          <div>
            <li className='footer-nav-item footer-social-media social-nav-list'>
              <a
                href='https://www.instagram.com/moduluslabs/'
              >
                <InstagramLogo 
                  fill='#ffffff'
                />
              </a>
              <a
                href='https://www.youtube.com/channel/UC3DUt4r1RCQucc3OX3LNO4g'
              >
                <YoutubeLogo 
                  fill='#ffffff'
                />
              </a>
            </li>
          </div>
        </React.Fragment>
      );
    }
    return null;
  }

  return (
    <footer className='Footer footer-col-section'>
      {TopFooter()}
      <div>
        <p className='copyright'>Copyright © 2021 Modulus Labs</p>
      </div>
    </footer>
  )
}

export default Footer;