import React from 'react';

const TextColumn = (props) => {

  return (
    <React.Fragment>
      {props.headerText !== undefined ? 
        <h1 className='sb-h'>{props.headerText}</h1> :
        null
      }
      {props.subHeaderText !== undefined ? 
        <h2 className='sb-h'>{props.subHeaderText}</h2> :
        null
      }
      <p className='desc'>
        {props.descText}
      </p>
    </React.Fragment>
  )
}  
export default TextColumn;