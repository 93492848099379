import React, { useState } from 'react';

import TextColumn from '../components/TextColumn';
import Section from '../components/Section';
import AboutHeader from '../components/AboutHeader';

import btsLogo from '../assets/images/about_behind_the_science.jpg';
import btsGif from '../assets/images/about_behind_the_science_hover.gif';
import communityLogo from '../assets/images/undraw_community_8nwl.svg';
import unityLogo from '../assets/images/unity-mwu-white.png';
import scienceLogo from '../assets/images/undraw_creative_experiment_8dk3.svg';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


const BehindTheScience = (props) => {
  const [isGifChange, setGifChange] = useState(false);

  return (
    <React.Fragment>
      <Helmet>
        <title>Modulus Labs | Behind the Science</title>
      </Helmet>
      <Section
        style={
          {background: '#008D9B'}
        }
        content={
          <AboutHeader
            width={props.width}
          />
        }
        className='col-section about-header-section'
      />
      <Section 
        style={
          {background: '#008D9B'}
        }
        content={
          <div className={props.width > 760 ? 
                          'row-section afy2q1 grid-box fixed-grid' :
                          'col-section afy2q1 grid-box fixed-grid'}>
              <div className='box-desc tc-784k'>
                <img className='column-image' src={scienceLogo}></img>
                <TextColumn
                  headerText='Science!'
                  descText='The sciences are the building blocks of everyday life.  
                            We simplify topics to create exciting labs 
                            that help explain the world we live in.'
                />
              </div>
              <div className='box-desc tc-784k'>
                <img className='unity-image' src={unityLogo}></img>
                <TextColumn
                  headerText='Unity!'
                  descText='Unity is the main source of our simulations.  
                            It’s an open-source game engine that 
                            holds different assets and functionalities 
                            that make our simulations unique and possible.'
                />
              </div>
              <div className='box-desc tc-784k'>
                <img className='column-image' src={communityLogo}></img>
                <TextColumn
                  headerText='Community!'
                  descText='We proudly accept feedback and suggestions to 
                            continuously improve and create simulations 
                            with the help of users, educators, and students. '
                />
              </div>
          </div>
        }
        className={props.width > 760 ? 'col-section ge452l unity-section' : 'about-bts-section ge452l col-section'}
      />
      <Section 
        style={
          {background: '#007493'}
        }
        content={
          <React.Fragment>
            <div className='col-section ge452l'>
              <div>
                <h1 className='sub-h'>Check behind the simulations!</h1>
              </div>
              <div>
                <a
                  onMouseEnter={() => setGifChange(!isGifChange)} 
                  onMouseLeave={() => setGifChange(!isGifChange)}  
                  className='link-button' 
                  href='https://lifeofpenguin.wordpress.com/'
                >
                  Behind Forces/Friction Lab!
                </a>
              </div>
            </div>
            <div>
              <img 
                className='column-image' 
                src={isGifChange ? btsGif : btsLogo}
              >
              </img>
            </div>
          </React.Fragment>
        }
        className={props.width > 760 ? 'row-section jkdhf3 half-section' : 'half-section ge452l col-section'}
      />
      <Section
        style={
          {background: '#2D5F74'}
        }
        content={
          <React.Fragment>
            <div>
              <h1 className='sub-h'>Find out the team behind Modulus Labs!</h1>
            </div>
            <Link
              className='link-button'
              to='/about/team'
            >
              Find out the Team
            </Link>
          </React.Fragment>
        }
        className={props.width > 760 ? 
        'col-section ge452l quarter-section quarter-section-padding' : 
        'col-section ge452l half-section'}
      />
    </React.Fragment>

  );
}

export default BehindTheScience;