import React from 'react';

import { Link } from 'react-router-dom';


const AboutHeader = (props) => {
  return (
    <div className={props.width > 760 ? 
                    'row-section jkdhf3 bor-bot aerfas' : 
                    'col-section ge452l bor-bot aerfas'}>
      <div>
        <Link
          className='link-button'
          to='/about'
        >
          About
        </Link>
      </div>
      <div>
        <Link
          className='link-button'
          to='/about/behind-the-science'
        >
          Behind the Science
        </Link>
      </div>
      <div>
        <Link
          className='link-button'
          to='/about/team'
        >
          Team
        </Link>
      </div>
    </div>
  )
}

export default AboutHeader;