import Home from './pages/Home';
import Simulations from './pages/Simulations';
import About from './pages/About';
import Error from './pages/Error';
import Contact from './pages/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import BehindTheScience from './pages/BehindTheScience';
import TeacherResources from './pages/TeacherResources';
import Team from './pages/Team';
import Terms from './pages/Terms';
import SimulationLab from './pages/SimulationLab';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import { useEffect, useState } from 'react';


const App = () => {

  const [width, setWindowWidth] = useState(0);

  const [responsiveDropMenu, setDropMenu] = useState(width > 760);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  }

  const updateDropMenuStatus = () => {
    setDropMenu(width > 760);
  }

  useEffect(() => {
    updateDimensions();
    updateDropMenuStatus();

    window.addEventListener("resize", updateDimensions);

    return () => 
      window.removeEventListener("resize", updateDimensions);

  });

  return (
    <Router>
      <ScrollToTop />
      <Header 
        width={width}
        responsiveDropMenu={responsiveDropMenu}
      />
      <div className="App">
        <Switch>
          <Route exact path='/'>
            <Home 
              width={width}
            />
          </Route>
          <Route exact path='/simulations'>
            <Simulations 
              width={width}
            />
          </Route>
          <Route path='/simulations/simulation-lab'>
            <SimulationLab 
              width={width}
            />
          </Route>
          <Route exact path='/about'>
            <About 
              width={width}
            />
          </Route>
          <Route path='/about/behind-the-science'>
            <BehindTheScience 
              width={width}
            />
          </Route>
          <Route path='/about/team'>
            <Team 
              width={width}
            />
          </Route>
          <Route path='/teachers'>
            <TeacherResources
              width={width}
            />
          </Route>
          <Route path='/contact'>
            <Contact 
              width={width}
            />
          </Route>
          <Route path='/terms-and-conditions'>
            <Terms />
          </Route>
          <Route path='*'>
            <Error />
          </Route>
        </Switch>
      </div>
    <Footer 
      width={width}
    />
    </Router>
  );
}

export default App;
