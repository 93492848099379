import React from 'react';
import Section from '../components/Section';
import TextColumn from '../components/TextColumn';
import AboutHeader from '../components/AboutHeader';

import TeamMemberOne from '../assets/images/team_penguin_1.png';
import TeamMemberTwo from '../assets/images/team_penguin_2.jpg';
import TeamMemberThree from '../assets/images/team_penguin_3.jpg';
import TeamMemberFour from '../assets/images/team_penguin_4.jpg';

import { Helmet } from 'react-helmet';

const Team = (props) => {
  const teamMembers = [
    { 
      key: 1,
      name: 'Jared Gochuico, P.E', 
      position: 'Unity Developer and Director',
      desc: 'Jared is a Unity developer and the director of Modulus Labs who graduated from San Jose State University. As Jared is a Professional Engineer (P.E) who designs random structural stuff for buildings, he also started programming projects and games for fun.',
      picture: TeamMemberOne,
    },
    {
      key: 2,
      name: 'Michael Tamin',
      position: 'Unity Developer',
      desc: 'Michael is one of the Unity Developers who works closely with Jared to finalize the programming. Currently, Michael serves as an transportation engineer, redesigning city streets to be more sustainable and safe.',
      picture: TeamMemberTwo
    },
    {
      key: 3,
      name: 'Lac Phan',
      position: 'Web Designer and Developer',
      desc: 'Lac Phan is the UI/UX designer and web developer of Modulus Labs. With the passions of web development and educating students in coding, Lac aims to provide a platform that is user-friendly for teachers and most importantly, students.',
      picture: TeamMemberThree
    },
    {
      key: 4,
      name: 'Reena Alog',
      position: 'Project Manager',
      desc: 'Reena is the Project Manager who provided support throughout the entire process. As with her background in applied mathematics, beliefs in early STEM education, and experience in teaching younger students, Reena hopes that many will benefit from these simulations.',
      picture: TeamMemberFour
    }
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>Modulus Labs | Team</title>
      </Helmet>
      <Section
        style={
          {background: '#008D9B'}
        }
        content={
          <AboutHeader
            width={props.width}
          />
        }
        className='col-section about-header-section'
      />
      <Section
        style={
          {background: '#008D9B'}
        }
        content={
          <div className={props.width > 760 ? 
                          'grid-two-by-two' :
                          'col-section fixed-grid'}>
            {teamMembers.map(teamMember => {
              return (
                <div key={teamMember.key} className='grid-two-box box-desc tc-784k'>
                  <div>
                    <img className='column-image' src={teamMember.picture}></img>
                  </div>
                  <div>
                    <TextColumn
                      headerText={teamMember.name}
                      subHeaderText={teamMember.position}
                      descText={teamMember.desc}
                    />
                  </div>
                </div>
                );
              }
            )}
          </div>
        }
        className='col-section team-section'
      />
      
    </React.Fragment>

  );
}

export default Team;