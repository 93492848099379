import React from 'react';

import Section from '../components/Section';
import TextColumn from '../components/TextColumn';
import AboutHeader from '../components/AboutHeader';

import originLogo from '../assets/images/undraw_design_community_rcft.svg';
import futureLogo from '../assets/images/undraw_maker_launch_crhe.svg';
import missionLogo from '../assets/images/undraw_mission_impossible_bwa2.svg';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


const About = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Modulus Labs | About</title>
      </Helmet>
      <Section
        style={
          {background: '#008D9B'}
        }
        content={
          <AboutHeader 
            width={props.width}
          />
        }
        className='col-section about-header-section'
      />
      <Section 
        style={
          {background: '#008D9B'}
        }
        content={
          <React.Fragment>
            <div>
              <img className='column-image' src={missionLogo}>
              </img>
            </div>
            <div className='box-desc'>
              <TextColumn 
                headerText='Our mission'
                descText='Our mission is to bring high-quality STEM education
                          to educational institutions.  
                          Our commitment to quality includes: tested simulations 
                          and robust code for a better user experience.'
              />
            </div>
          </React.Fragment>
        }
        className={props.width > 760 ? 'row-section jkdhf3 half-section' : 'col-section ge452l section'}
      />
      <Section
        style={
          {background: '#007493'}
        }
        content={
          <React.Fragment>
            <div>
              <img className='column-image' src={originLogo}>
              </img>
            </div>
            <div className='box-desc'>
              <TextColumn 
                headerText='Our origins'
                descText='It all began through our passion of STEM and the realization that 
                          the resources to supplement 
                          in-class STEM learning are either too expensive, outdated, or lacking.  
                          We aim to give back to the community using insightful, 
                          educational tools to provide an effective, 
                          visual learning experience in an attempt to increase retention and 
                          interest in STEM topics.'
              />
            </div>
          </React.Fragment>
        }
        className={props.width > 760 ? 'row-section jkdhf3 half-section' : 'col-section ge452l section'}
      />
      <Section
        style={
          {background: '#007FAF'}
        }
        content={
          <React.Fragment>
            <div>
              <img className='column-image' src={futureLogo}>
              </img>
            </div>
            <div className='box-desc'>
              <TextColumn 
                headerText='Future prospects'
                descText='We strive to eventually produce several simulations 
                          that cover a variety of STEM concepts for a 
                          unique and beneficial experience for teachers, students, 
                          and anyone in general.'
              />
            </div>
          </React.Fragment>
        }
        className={props.width > 760 ? 'row-section jkdhf3 half-section' : 'col-section ge452l section'}
      />
      <Section
        style={
          {background: '#2D5F74'}
        }
        content={
          <React.Fragment>
            <div>
              <h1 className='sub-h'>See behind the scenes of how we made the science!</h1>
            </div>
            <Link
              className='link-button'
              to='/about/behind-the-science'
            >
              Behind the science
            </Link>
          </React.Fragment>
        }
        className={props.width > 760 ? 
          'col-section ge452l quarter-section quarter-section-padding' : 
          'col-section ge452l half-section'}
      />
      
    </React.Fragment>

  );
}

export default About;