import React from 'react';


const Section = (props) => {
  return (
    <section style={
                  props.style
               } className={props.className} >
      {props.content}
    </section>
  )
}

export default Section;