import {ReactComponent as WebsiteLogo} from '../assets/images/logo.svg';
import { Link, useLocation } from 'react-router-dom';

import HeaderNavBar from './HeaderNavBar';

import { ReactComponent as DropDownMenuIcon } from '../assets/images/iconmonstr-menu-1.svg';
import { ReactComponent as CloseIcon } from '../assets/images/iconmonstr-x-mark-7.svg';

import React, { useState, useEffect } from 'react';


const Header = (props) => {
  const [showDropMenu, setShowDropMenu] = useState(false);

  function CurrentHeader() {
    const location = useLocation();
    if (location.pathname === '/')
      return 'Home-Header Header';
    return 'Header';
  }

  function DropDownMenu() {
    return (
      <div 
        className={showDropMenu ? 'dropdown show' : 'dropdown hide'}
      >
        <HeaderNavBar 
          navListType='dropdown-nav-list'
        />
      </div>
    )
  }

  useEffect(() => {
    if (props.width > 760)
      setShowDropMenu(false);
    
  }, []);

  return (
    <React.Fragment>
      <header className={CurrentHeader()}>
        <div>
          <Link
            to='/'
          >
            <WebsiteLogo 
              className='logo-image'
            />
          </Link>
        </div>
        {props.responsiveDropMenu ? 
          <HeaderNavBar 
            navListType='nav-list'
          /> : 
          !showDropMenu ? 
            <DropDownMenuIcon 
              className='dropdown-cursor'
              onClick={() => setShowDropMenu(!showDropMenu)}
              fill='#ffffff'
            /> : 
            <CloseIcon
              className='dropdown-cursor'
              onClick={() => setShowDropMenu(!showDropMenu)}
              fill='#ffffff'
            />
          }
      </header>
      {DropDownMenu()}
    </React.Fragment>
  );
}

export default Header;