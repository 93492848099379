import React, { useState, useEffect } from "react";

import { ReactComponent as InfoButton} from '../assets/images/iconmonstr-info-7.svg'

import Section from "../components/Section";
import TextColumn from "../components/TextColumn";

import { Helmet } from 'react-helmet'
import Unity, { UnityContext } from "react-unity-webgl";
import { Link, useLocation } from 'react-router-dom';

const SimulationLab = (props) => {
  const [progression, setProgress] = useState(0);
  const labContent = [
    { 
      labName: 'Force & Mass Lab',
      labDesc: "Use the arrow keys. Use the \"up\" key to launch the player and the \"left\ and \"right\" keys to move the platforms. Use the buttons in-game to restart or progress through the levels. ",
      unityContent: {
        loaderUrl: "/simulations/forcemasslab04252021 - 1366p.loader.js",
        dataUrl: "/simulations/forcemasslab04252021 - 1366p.data",
        frameworkUrl: "/simulations/forcemasslab04252021 - 1366p.framework.js",
        codeUrl: "/simulations/forcemasslab04252021 - 1366p.wasm"
    }},
    { 
      labName: 'Friction Lab',
      labDesc: "Use the arrow keys. Use the \"up\" key to launch the player and the \"left\ and \"right\" keys to move the platforms. Use the buttons in-game to restart or progress through the levels.",
      unityContent: {
        loaderUrl: "/simulations/frictionlab04252021 - 1366p.loader.js",
        dataUrl: "/simulations/frictionlab04252021 - 1366p.data",
        frameworkUrl: "/simulations/frictionlab04252021 - 1366p.framework.js",
        codeUrl: "/simulations/frictionlab04252021 - 1366p.wasm"
    }},
    { 
      labName: 'Collision Theory Lab',
      labDesc: "Use the menu in the top left to observe different scenarios for the molecules: (1) Mixing, (2) temperature, and (3) concentration. In each scenario, use the in-game buttons and sliders, and when you're ready, click \"Play\" to see what happens.",
      unityContent: {
        loaderUrl: "/simulations/chem-collision-theory_9.5.21-v2.loader.js",
        dataUrl: "/simulations/chem-collision-theory_9.5.21-v2.data",
        frameworkUrl: "/simulations/chem-collision-theory_9.5.21-v2.framework.js",
        codeUrl: "/simulations/chem-collision-theory_9.5.21-v2.wasm"
    }}
  ]
  const simulationNumber = SimulationNumber();
  const unityPlayer = UnityPlayer();

  function SimulationNumber() {
    let location = useLocation();
    if (location.pathname === '/simulations/simulation-lab/forces-lab')
      return 0;
    else if (location.pathname === '/simulations/simulation-lab/friction-lab')
      return 1;
    else if (location.pathname === '/simulations/simulation-lab/collision-theory-lab')
      return 2;
    else if (location.pathname === '/simulations/simulation-lab/covalent-bond-lab')
      return 3;  

  }

  function UnityPlayer() {
    return new UnityContext(labContent[simulationNumber].unityContent);
  }

  unityPlayer.on("canvas", (canvas) => {
    canvas.width = `${props.width * .8}px`;
    canvas.height = `${props.height * .4}px`;
    canvas.maxWidth = '100%';
    canvas.maxHeight = '100%';
  });

  unityPlayer.on("progress", (progression) => {
    setProgress(progression);
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>Modulus Labs | {labContent[simulationNumber].labName}</title>
      </Helmet>
      <Section 
        style={
          {background: '#008D9B'}
        }
        content={
          <div>
            {progression < 1 ?
              <p className='loading'>Loading...</p> :
              null
            }
            <Unity 
              unityContext={unityPlayer}
              matchWebGLToCanvasSize={true} 
              className='unityContainer'
              width= {props.width * .8}
              height= {props.width * .45}
            />
          </div>
        }
        className="col-section ge452l unity-section aewr31"
      />
      <Section 
        style={
         {background: '#008D9B'}
        }
        content={
          <React.Fragment>
            <div className={props.width > 760 ? 
                            'simulation-desc row-section je452l' :
                            'simulation-desc col-section ge452l'}>
              <div className={props.width > 760 ? 'col-section l-3ugir boer43' : 'col-section t-3ugir'}>
                <div className='box-desc'>
                  <TextColumn
                    headerText={
                      <React.Fragment>
                        {labContent[simulationNumber].labName}
                      
                      </React.Fragment>
                    }
                    descText={`Instruction: ${labContent[simulationNumber].labDesc}`}
                  />
                  <Link
                    className='oearfz'
                    to='/teachers'
                  >
                    Teacher Resources
                  </Link>
                </div>
              </div>
              <div>
                <a className='link-button' href='https://lifeofpenguin.wordpress.com/'>
                  Behind the Science
                </a>
              </div>
            </div>
          </React.Fragment>
        }
        className="row-section half-section a43jfr mt-983k"
      />
    </React.Fragment>
  );
}

export default SimulationLab;