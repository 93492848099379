import React, { useState } from 'react';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const LessonPlan = (props) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      {props.headerText !== undefined ? 
        <h1 className='sb-h'>{props.headerText}</h1> :
        null
      }
      <div>
        <Document
          className='lesson-plan-container'
          file={'/pdfs/8 Science_Forces and Friction Simulation Lab.pdf'}
          onLoadSuccess={onDocumentLoadSuccess}
        >
            {[...Array(numPages)].map((numPage, index) => (
            <div>
              <Page
                onClick={props.setPageNumber !== undefined ? () => {props.setPageNumber(index + 1); props.setIsOpen(true)} : null}
                className='page-container'
                width={150}
                height={150}
                pageNumber={index + 1} 
              />
            </div>
            ))}
        </Document>
      </div>
    </>
  )
}

export default LessonPlan