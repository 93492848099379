import { Link } from 'react-router-dom';

const HeaderNavBar = (props) => {
  return (
    <div>
      <ul className={props.navListType}>
        <li className='nav-item'>
          <Link
            className='nav-link'
            to='/simulations'
          >
            Simulations
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className='nav-link'
            to='/teachers'
          >
            Teachers
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className='nav-link'
            to='/about'
          >
            About
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className='nav-link'
            to='/contact'
          >
            Contact
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default HeaderNavBar;