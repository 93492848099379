import ContactImage from '../assets/images/undraw_contact_us_15o2.svg';

import Section from '../components/Section';
import TextColumn from '../components/TextColumn';
import React from 'react';

import { Helmet } from 'react-helmet';


const Contact = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Modulus Labs | Contact</title>
      </Helmet>
      <Section
        style={
          {background: '#008D9B'}
        }
        content={
          <React.Fragment>
            <div className='box-desc bor-bot'>
              <TextColumn 
                headerText='Contact'
                descText='Got any questions or feedback? Let us know!'
              />
            </div>
          </React.Fragment>
        }
        className='col-section quarter-section'
      />
      <Section
        style={
          {background: '#008D9B'}
        }
        content={
          <React.Fragment>
            <div className={props.width > 760 ? 
                            'row-section jkdhf3 grid-box fixed-grid' :
                            'col-section grid-box fixed-grid'}>
              <div className={props.width > 760 ? 
                            'box-desc ow0zr9' :
                            'box-desc'}>
                <TextColumn 
                  headerText='For Teachers:'
                  descText='For educational tools and solutions to simulations, contact us at contact@moduluslabs.org.'
                />
                
                <img className='column-image' src={ContactImage}></img>
              </div>
              <div className='box-desc'>
                <TextColumn 
                  headerText='Have any feedback for our recent simulations?'
                  descText='Click below to use our Google Forms for the simulations!'
                />
                <a 
                  className='link-button' 
                  href='https://docs.google.com/forms/d/e/1FAIpQLSc7LTqkcFV-wHJ7PO-C-8zOrVDSJzUyWH4UV4mM55IXwYybPQ/viewform'
                >
                  Fill out your response!
                </a>
              </div>
            </div>
          </React.Fragment>
        }
        className={props.width > 760 ? 'col-section half-section a43jfr' : 'col-section section a43jfr'}
      />
    </React.Fragment>
  )
}

export default Contact;