import React, { useState } from "react";

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';


const PDFModal = (props) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    !props.open ? null :
      <div className='modal' >
        <div className='modal-content'>
          <div onClick={props.setOpen} className='link-button exit-button'>
            X
          </div>
          <Document
            className='lesson-plan-container'
            file={'/pdfs/8 Science_Forces and Friction Simulation Lab.pdf'}
            onLoadSuccess={onDocumentLoadSuccess}
            >
              <div>
                <Page
                  className='page-container'
                  width={500}
                  height={500}
                  pageNumber={props.pageNumber} 
                />
              </div>
          </Document>
        </div>
      </div>
  )
}

export default PDFModal;