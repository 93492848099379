import React from 'react';

import Section from '../components/Section';
import TextColumn from '../components/TextColumn';

import heroBackground from '../assets/images/hero_background.png';
import simulationLogo from '../assets/images/undraw_select_player_64ca.svg'
import studentLogo from '../assets/images/undraw_Reading_book_re_kqpk.svg';
import teacherLogo from '../assets/images/undraw_teacher_35j2.svg';
import btsLogo from '../assets/images/behind_the_science_image.gif';
import schoolLogo from '../assets/images/St_Francis_Logo.png';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Home = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Modulus Labs | Home</title>
      </Helmet>
      <Section 
        style={
          {background: `url(\'${heroBackground}\')`,
          backgroundPosition: `${props.width > 760 ? '50% 75%' : '75% 75%'}`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'}
        }
        content={
          <div
            style={
              props.width > 760 ? {marginLeft: `${props.width - 720}px`} : null
            } 
            className='box-desc'
          >
            <TextColumn 
              headerText='Imagine Science differently.'
              descText='Providing modern visual simulations for learning STEM in a web browser.'
            />
            <Link
              className='link-button'
              to='/simulations'
            >
              Check out Simulations
            </Link>
          </div>
        }
        className={props.width > 760 ? 'row-section home-section' : 'col-section ge452l section'}
      />
      <Section 
        style={
          {background: '#008D9B'}
        }
        content={
          <React.Fragment>
            <div>
              <iframe 
                width="375" 
                height="275" 
                src="https://www.youtube.com/embed/ztzq58zDSxE" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>  
              </iframe>
            </div>
            <div className='box-desc'>
              <TextColumn 
                headerText='What is a simulation?'
                descText='A simulation is an interactive web-based lab focused on 
                          different STEM concepts. Our simulations are open source 
                          3D environments that enhance visual learning.'
              />
              <Link
                className='link-button'
                to='/simulations'
              >
                Try a Simulation out
              </Link>
            </div>
          </React.Fragment>
        }
        className={props.width > 760 ? 'row-section jkdhf3 half-section' : 'col-section ge452l section'}
      />
      <Section
        style={
          {background: '#007FAF'}
        }
        content={
          <React.Fragment>
            <div>
              <h1 className='sub-h'>Labs For Who?</h1>
            </div>
            <div className={props.width > 760 ? 
                            'row-section jkdhf3 grid-box fixed-grid' :
                            'col-section jkdhf3 grid-box fixed-grid'}>
              <div className='box-desc tc-784k'>
                <img className='column-image' src={teacherLogo}></img>
                <TextColumn
                  subHeaderText='Easy for Teachers'
                  descText='Teachers can use these simulations as 
                            part of their STEM teaching curriculum and also
                            act as virtual replacements for any labs that
                            are usually conducted in an in-person classroom.'
                />
              </div>
              <div className='box-desc tc-784k'>
                <img className='column-image' src={studentLogo}></img>
                <TextColumn
                  subHeaderText='Beneficial For Students'
                  descText='Students interact with gamified 3-D simulations 
                            that influences their interest in STEM and help them 
                            understand concepts visually via analyzing data and 
                            discovering solutions.'
                />
              </div>
            </div>
            <div>
              <Link
                className='link-button'
                to='/about'
              >
                Learn more about us
              </Link>
            </div>
          </React.Fragment>
        }
        className={props.width > 760 ? 'col-section ge452l section' : 'mobile-section ge452l col-section'}
      />
      <Section
        style={
          {background: '#007493'}
        }
        content={
          <React.Fragment>
            <div>
              <img className='column-image' src={btsLogo}>
              </img>
            </div>
            <div className='box-desc'>
              <div>
                <TextColumn 
                  headerText='Behind the Simulations!'
                  descText='Take a look at how we built our simulations
                            through a timeline and three main aspects: Science,
                            the game engine Unity, and the Community.'
                />
              </div>
              <div>
                <Link
                  className='link-button'
                  to='/about/behind-the-science'
                >
                  See behind the science
                </Link>
              </div>
            </div>
          </React.Fragment>
        }
        className={props.width > 760 ? 'row-section jkdhf3 half-section' : 'col-section ge452l section'}
      />
      <Section
        style={
          {background: '#0078BA'}
        }
        content={
          <React.Fragment>
            <div className='col-section'>
              <div>
                <h1>In collaboration with</h1>
              </div>
              <div>
                <img className='collab-image' src={schoolLogo} />
              </div>
            </div>
          </React.Fragment>
        }
        className={props.width > 760 ? 
          'col-section ge452l quarter-section quarter-section-padding' : 
          'col-section ge452l half-section'}
      />
      <Section
        style={
          {background: '#2D5F74'}
        }
        content={
          <React.Fragment>
            <div>
              <h1 className='sub-h'>Are you ready to simulate?</h1>
            </div>
            <div>
              <Link
                className='link-button'
                to='/simulations'
              >
                Start
              </Link>
            </div>
          </React.Fragment>
        }
        className={props.width > 760 ? 
                    'col-section ge452l quarter-section quarter-section-padding' : 
                    'col-section ge452l half-section'}
      />
      
    </React.Fragment>

  );
}

export default Home;