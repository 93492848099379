import ErrorImage from '../assets/images/undraw_page_not_found_su7k.svg';

import Section from '../components/Section';
import React from 'react';

import { Helmet } from 'react-helmet';


const Error = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Modulus Labs | 404 Not Found</title>
      </Helmet>
      <Section
        style={
          {background: '#FFFFFF'}
        }
        content={
          <React.Fragment>
            <div className= 'col-section'>
              <div>
                <img className='column-image' src={ErrorImage}></img>
              </div>
              <div>
                <p>Page not found!</p>
              </div>
            </div>
          </React.Fragment>
        }
        className='col-section half-section e-t3p mt-983k'
      />
    </React.Fragment>
  )
}

export default Error;